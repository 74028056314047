import React, { useState } from "react"
import "./LoginPage.scss"
import { ReactComponent as Ringover } from "../../assets/Icons/ringover.svg"
import InputText from "../../components/InputText/InputText"
import Button from "../../components/Button/Button"
import useLogin from "../../custom-hooks/useLogin"
import { ReactComponent as Loader } from "../../assets/Icons/loading.svg"

const LoginPage = ({ history }) => {
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const { loading, onLogin, message } = useLogin()

  const handleLogin = async (username, password) => {
    await onLogin({ username, password })
  }

  return (
    <div className="login-page">
      <div className="ringover-logo">
        <Ringover /> <span>ringover</span>
      </div>
      <div className="login-box-container">
        <div className="login-box">
          <div className="heading">Sign in</div>
          <InputText value={username} setValue={setUsername} label="Username" width="280px" />
          <InputText type={"password"} value={password} setValue={setPassword} label="Password" width="280px" />
          {message !== "" && <div className="failure">{message}</div>}
          <div className="submit">
            <span onClick={() => handleLogin(username, password)}>
              {!loading ? (
                <Button>Sign in</Button>
              ) : (
                <Button>
                  Signing In <Loader />
                </Button>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
