import { useState } from "react"
import axios from "axios"

const useUsers = () => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState()

  const fetchUsers = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_NODE_BACKEND_URL}/users`,
        method: "get",
      })
      const body = await response.data

      console.log(body)
      if (response.status === 200) {
        setUsers(body.data)
      }
    } catch (err) {}
  }

  const changePermissions = async (params) => {
    const field = params.colDef.field
    const value = !params.data[field]
    if (field === "individual_crms_option") {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_NODE_BACKEND_URL}/users/${params.data._id}`,
          method: "patch",
          data: {
            individual_crms_option: value,
          },
        })
        console.log(response)
        if (response.status === 200) {
          // setUsers(prevState=>[...prevState,prevState[params.node.rowIndex]])
          await fetchUsers()
        }
      } catch (e) {
        console.log(e)
      }
    } else if (field === "individual_phone_numbers_option") {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_NODE_BACKEND_URL}/users/${params.data._id}`,
          method: "patch",
          data: {
            individual_phone_numbers_option: value,
          },
        })
        // console.log(response)
        if (response.status === 200) {
          fetchUsers()
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const updateMaxScrapings = async (params) => {
    // const field = params.colDef.field
    const value = params.newValue.substr(params.newValue.lastIndexOf("/") + 1)
    setLoading(true)
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_NODE_BACKEND_URL}/users/${params.data._id}`,
        method: "patch",
        data: {
          max_scrapings: value,
        },
      })

      // console.log(response)
      if (response.status === 200) {
        // setUsers(prevState=>[...prevState,prevState[params.node.rowIndex]])
        await fetchUsers()
      }
    } catch (e) {
      console.log(e)
    }
  }

  return { loading, fetchUsers, users, setUsers, changePermissions, updateMaxScrapings, setLoading }
}

export default useUsers
