import { useState } from "react"
import axios from "axios"
import { ACCESS_TOKEN } from "../constants/constants"

const useLogin = () => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)

  const onLogin = async (data) => {
    setLoading(true)
    setMessage(" ")
    console.log(data)
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_NODE_BACKEND_URL}/admin/login`,
        method: "post",
        data: data,
      })
      const body = await response.data
      setLoading(false)
      console.log(body)
      if (response.status === 200) {
        localStorage.setItem(ACCESS_TOKEN, body.data.accessToken)
        window.location.href = "/dashboard"
      } else {
        setMessage("Inconnect username or password")
      }
    } catch (err) {
      setMessage("Inconnect username or password")
      setLoading(false)
    }
  }
  return { loading, message, setMessage, onLogin }
}

export default useLogin
