import React from "react"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-material.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "./ExtractedDataTable.scss"

function ExtractedDataTable({ TABLE_DATA }) {
  const srNoStyles = {
    paddingRight: "20px",
  }
  const aboutStyles = {
    justifyContent: "flex-start",
    paddingLeft: "25px",
  }
  const gridOptions = {
    rowHeight: 69,
  }

  return (
    <div className="extractedData-ag-table">
      <div className="ag-theme-material">
        <AgGridReact rowData={TABLE_DATA} pagination={true} defaultColDef={{ editable: true }} resizable={true} paginationPageSize={8} paginationAutoPageSize={true} gridOptions={gridOptions}>
          <AgGridColumn field="no" headerName="No." cellStyle={srNoStyles} valueGetter={(params) => params.node.rowIndex + 1} sortable={true} filter={true} width={100}></AgGridColumn>
          <AgGridColumn field="firstname" headerName="First Name" valueGetter={(params) => (params.data.first_name ? params.data.first_name : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="last Name" headerName="Last Name" valueGetter={(params) => (params.data.last_name ? params.data.last_name : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="fullName" headerName="Full Name" valueGetter={(params) => (params.data.first_name ? params.data.first_name + " " + params.data.last_name : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="company_name" headerName="Company name" valueGetter={(params) => (params.data.company_name ? params.data.company_name : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="company_url" headerName="CompanyURL" valueGetter={(params) => (params.data.company_url ? params.data.company_url : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="company_size" headerName="Company size" valueGetter={(params) => (params.data.company_size ? params.data.company_size : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="location" headerName="Location" valueGetter={(params) => (params.data.location ? params.data.location : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="personal_emails" headerName="Personal Email" valueGetter={(params) => (params.data.personal_emails ? params.data.personal_emails : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="caompany_emails" headerName="Company Email" valueGetter={(params) => (params.data.company_emails.length !== 0 ? params.data.company_emails : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="individual_crms" headerName="Individiual CRM" valueGetter={(params) => (params.data.individual_crms.length !== 0 ? params.data.individual_crms : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="company_crms" headerName="Company CRM" valueGetter={(params) => (params.data.company_crms.length !== 0 ? params.data.company_crms : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="company_linkedin_url" headerName="Company LinkedIn URL" valueGetter={(params) => (params.data.company_linkedin_url ? params.data.company_linkedin_url : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="individual_phone_numbers" headerName="Personal Phone Number" valueGetter={(params) => (params.data.individual_phone_numbers.length !== 0 ? params.data.individual_phone_numbers : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="company_phone_numbers" headerName="Company Phone Number" valueGetter={(params) => (params.data.company_phone_numbers.length !== 0 ? params.data.company_phone_numbers : "-")} sortable={true} filter={true}></AgGridColumn>
          <AgGridColumn field="company_description" headerName="About" cellStyle={aboutStyles} valueGetter={(params) => (params.data.company_description ? params.data.company_description : "-")} sortable={true} filter={true} width={600}></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  )
}

export default ExtractedDataTable
