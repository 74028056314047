import { BrowserRouter, Route, Switch } from "react-router-dom"
import LoginPage from "./Pages/LoginPage/LoginPage"
import Dashboard from "./Pages/Dashboard/Dashboard"
import ExtractedData from "./Pages/ExtractedData/ExtractedData.jsx"
import "./App.scss"
import PrivateRoute from "./components/PrivateRoute/PrivateRoute"
import AuthProvider from "./context/AuthContext"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Switch>
            <Route path="/" exact component={LoginPage} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/extracted-data/:id" component={ExtractedData} />
            <Route path="/">Page Not Found</Route>
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
