import React from "react"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-material.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import "./DashboardTable.scss"
import Eye from "../../assets/Icons/eye.svg"
import moment from "moment"

const srNoStyles = {
  paddingLeft: "23px",
}
const nameStyles = {
  paddingLeft: "30px",
}
const urlStyles = {
  paddingLeft: "20px",
}
const DOAStyles = {
  paddingLeft: "60px",
}
const requestsStyles = {
  paddingLeft: "43px",
}
const actionStyles = {
  paddingLeft: "35px",
  display: "flex",
  alignContent: "center",
  paddingTop: "10px",
}
const gridOptions = {
  rowHeight: 69,
}
const checkboxStyles = {
  paddingLeft: "50px",
  cursor: "pointer",
}

function Table({ TABLE_DATA, setUsers, changePermissions, updateMaxScrapings }) {
  setInterval(() => {
    document.querySelector(".ag-popup")?.classList?.remove("ag-theme-material")
  }, 200)

  const actionsCellRenderer = (params) => {
    return `<a href="/extracted-data/${params.data._id}"> 
    <img src="${Eye}" width="28"/>  
      </a>`
  }
  const linkedInCellRenderer = (params) => {
    return `<a href="${params.data.linkedin_url}" target="_blank"> 
    ${params.data.linkedin_url}
      </a>`
  }

  const checkboxRenderer = (params) => {
    return `<div>
        <input type="checkbox" class="allowBox" ${params.data[params.colDef.field] ? "checked" : ""} />
      </div>`
  }

  const changeAccessibility = (params, setUsers) => {
    changePermissions(params, setUsers)
  }

  const maxScrapingsChanged = (params) => {
    if (params.oldValue !== params.newValue) {
      params.node.setDataValue("max_scrapings", params.newValue.substr(params.newValue.lastIndexOf("/") + 1))
      updateMaxScrapings(params)
    } // params.data.max_scrapings = params.newValue.substr(params.newValue.lastIndexOf("/") + 1)
  }

  return (
    <div className="dashboard-ag-table">
      <div className="ag-theme-material">
        <AgGridReact rowData={TABLE_DATA} pagination={true} paginationPageSize={8} paginationAutoPageSize={true} gridOptions={gridOptions}>
          <AgGridColumn field="no" headerName="No." cellStyle={srNoStyles} valueGetter={(params) => params.node.rowIndex + 1} sortable={true} filter={true} width={80}></AgGridColumn>
          <AgGridColumn field="fullName" headerName="Name" cellStyle={nameStyles} editable={true} valueGetter={(params) => (params.data.first_name ? params.data.first_name + " " + params.data?.last_name : "-")} sortable={true} filter={true} width={222}></AgGridColumn>
          <AgGridColumn field="individual_crms_option" headerName="Allow I-CRM" cellStyle={checkboxStyles} onCellClicked={(params) => changeAccessibility(params, setUsers)} cellRenderer={checkboxRenderer} editable={false} sortable={true} filter={true} width={140}></AgGridColumn>
          <AgGridColumn field="individual_phone_numbers_option" headerName="Allow I-Phone Number" cellStyle={checkboxStyles} onCellClicked={(params) => changeAccessibility(params, setUsers)} cellRenderer={checkboxRenderer} editable={false} sortable={true} filter={true} width={140}></AgGridColumn>
          <AgGridColumn field="linkedInURL" headerName="LinkedIn URL" cellStyle={urlStyles} resizable={true} cellRenderer={linkedInCellRenderer} sortable={true} filter={true} width={326}></AgGridColumn>
          <AgGridColumn field="company_name" headerName="Company Name" cellStyle={DOAStyles} valueGetter={(params) => (params.data.company_name ? params.data.company_name : "-")} sortable={true} width={200} filter={true}></AgGridColumn>
          <AgGridColumn field="DOA" headerName="Date of Authentication" cellStyle={DOAStyles} valueGetter={(params) => (params.data.created_at ? moment(params.data.created_at).calendar() : "-")} sortable={true} width={269} filter={true}></AgGridColumn>
          <AgGridColumn field="max_scrapings" headerName="Requests" cellStyle={requestsStyles} valueGetter={(params) => params.data.scrapings + "/" + params.data.max_scrapings} sortable={true} width={135} filter={true} editable={true} onCellValueChanged={maxScrapingsChanged}></AgGridColumn>
          <AgGridColumn field="actions" headerName="Actions" cellStyle={actionStyles} cellRenderer={actionsCellRenderer} sortable={true} width={115} filter={true}></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  )
}

export default Table
