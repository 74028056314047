import React, { useEffect, useContext, useState } from "react"
import "./Dashboard.scss"
import DashboardTable from "../../components/DashboardTable/DashboardTable"
import { ReactComponent as Ringover } from "../../assets/Icons/ringover.svg"
import { ReactComponent as Exit } from "../../assets/Icons/exit.svg"
import useUsers from "../../custom-hooks/useUsers"
import { AuthContext } from "../../context/AuthContext"
import { ReactComponent as Loader } from "../../assets/Icons/bigLoading.svg"

const Dashboard = () => {
  const { fetchUsers, users, setUsers, changePermissions, updateMaxScrapings } = useUsers()
  const { logout } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const run = async () => {
      await fetchUsers()
      setLoading(false)
    }
    run()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogout = async () => {
    logout()
  }

  return (
    <div className="dashboard">
      <div className="ringover-logo">
        <Ringover /> <span>ringover</span>
      </div>
      <div
        className="logout"
        onClick={() => {
          handleLogout()
        }}
      >
        <Exit />
      </div>
      <div className="table-container">
        {loading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <DashboardTable TABLE_DATA={users} setUsers={setUsers} changePermissions={changePermissions} updateMaxScrapings={updateMaxScrapings} />
        )}
      </div>
    </div>
  )
}

export default Dashboard
