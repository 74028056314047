import { useState } from "react"
import axios from "axios"

const useUserScrapedData = () => {
  const [loading, setLoading] = useState(false)
  const [scrapedProfiles, setScrapedProfiles] = useState()

  const fetchScrapedProfiles = async (id) => {
    setLoading(true)
    console.log(id)
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_NODE_BACKEND_URL}/users/${id}/profiles`,
        method: "get",
      })
      const body = await response.data
      setLoading(false)
      console.log(body)
      if (response.status === 200) {
        setScrapedProfiles(body.data.profiles)
        console.log(scrapedProfiles)
      }
    } catch (err) {
      setLoading(false)
    }
  }
  return { loading, fetchScrapedProfiles, scrapedProfiles }
}

export default useUserScrapedData
