import React from "react"
import "./InputText.scss"

const InputText = ({ className = "", setValue, value = "", label = "", width = "280px", ...props }) => {
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  return (
    <div className="input-box" style={{ width: width }}>
      <label>{label}</label>
      <input
        style={{ width: width }}
        type="text"
        className={className}
        value={value}
        onChange={(e) => {
          if (setValue) {
            handleChange(e)
          }
        }}
        {...props}
      />
    </div>
  )
}

export default InputText
