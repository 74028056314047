import React, { useEffect, useContext } from "react"
import "./ExtractedData.scss"
import ExtractedDataTable from "../../components/ExtractedDataTable/ExtractedDataTable"
import { ReactComponent as Ringover } from "../../assets/Icons/ringover.svg"
import { ReactComponent as LeftArrow } from "../../assets/Icons/leftArrow.svg"
import { ReactComponent as Exit } from "../../assets/Icons/exit.svg"
import useUserScrapedData from "../../custom-hooks/useUserScrapedData"
import { AuthContext } from "../../context/AuthContext"
import { useParams } from "react-router"
import { ReactComponent as Loader } from "../../assets/Icons/bigLoading.svg"

const ExtractedData = ({ history }) => {
  const { loading, fetchScrapedProfiles, scrapedProfiles } = useUserScrapedData()
  const { logout } = useContext(AuthContext)
  const { id } = useParams()

  useEffect(() => {
    fetchScrapedProfiles(id)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogout = async () => {
    logout()
  }

  return (
    <div className="extractedData">
      <div className="ringover-logo">
        <Ringover /> <span>ringover</span>
      </div>
      <div
        className="logout"
        onClick={() => {
          handleLogout()
        }}
      >
        <Exit />
      </div>
      <div
        className="back"
        onClick={() => {
          history.push("/dashboard")
        }}
      >
        {" "}
        <LeftArrow /> back
      </div>
      <div className="table-container">
        {loading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <ExtractedDataTable TABLE_DATA={scrapedProfiles} />
        )}
      </div>
    </div>
  )
}

export default ExtractedData
